import React from "react";
import classes from "./MoobiPlus.module.scss";
import Lotteryicon from "../../assets/MoobiPlusImages/lotto.png";
import Sportsbeticon from "../../assets/MoobiPlusImages/sportsbetting.png";
import OnlineCasinoicon from "../../assets/MoobiPlusImages/casino.png";

function CompanyGames() {

  return (
    <div className={classes.companyGamesContainer}>
      <div className={classes.hexaAllCont}>
        <div className={classes.outerBorderSmall}>
          <div className={classes.hexaInnerSmall}>
            <span className={classes.numberInside}>01</span>
          </div>
        </div>
        <div className={classes.outerBorderLargeFirst}>
          <div className={classes.hexaInnerLarge}>
            <div className={classes.hexagoninfo}>
              <img
                src={Lotteryicon}
                alt="Lottery icon"
                className={classes.iconStyle}
              />
              <div className={classes.titleParagraphDesktop}>
                <span className={classes.hexaTitle}>
                  Lottery <span className={classes.hexaSecondTitle}>Studio</span>
                </span>
                <p className={classes.hexaParagraph}>
                  Elevate your gaming experience with our cutting-edge Lottery
                  iStudio. Choose from a diverse selection of lottery games, all
                  designed with fairness, transparency, and top-tier security in
                  mind.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.outerBorderSmall}>
          <div className={classes.hexaInnerSmall}>
            <span className={classes.numberInside}>02</span>
          </div>
        </div>
      </div>
      <div className={classes.hexaAllCont}>
        <div className={classes.outerBorderLargeSecond}>
          <div className={classes.hexaInnerLarge}>
            <div className={classes.hexagoninfo}>
              <img
                src={Sportsbeticon}
                alt="Sportsbet icon"
                className={classes.iconStyle}
              />
              <div className={classes.titleParagraphDesktop}>
                <span className={classes.hexaTitle}>
                  Sports <span className={classes.hexaSecondTitle}>Betting</span>
                </span>
                <p className={classes.hexaParagraph}>
                  Dive into the thrill of sports with our advanced Sports Betting
                  platform. Place bets on your favorite teams and events with
                  competitive odds, live betting, and instant updates. Enjoy a
                  secure, seamless, and exciting betting experience.
                </p>

              </div>
            </div>
          </div>
        </div>
        <div className={classes.outerBorderLargeSecond}>
          <div className={classes.hexaInnerLarge}>
            <div className={classes.hexagoninfo}>
              <img
                src={OnlineCasinoicon}
                alt="OnlineCasino icon"
                className={classes.iconStyle}
              />
              <div className={classes.titleParagraphDesktop}>
                <span className={classes.hexaTitle}>
                  Online <span className={classes.hexaSecondTitle}>Casino</span>
                </span>
                <p className={classes.hexaParagraph}>
                  Discover the ultimate gaming adventure in our Online Casino.
                  Enjoy a premium casino experience from anywhere. Benefit from
                  stunning graphics, fair play, and the chance for big wins, all
                  in one secure platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.hexaAllCont}>
        <div className={classes.outerBorderSmallThird}>
          <div className={classes.hexaInnerSmall}>
            <span className={classes.numberInside}>03</span>
          </div>
        </div>
      </div>



      <div className={classes.hexaAllContMobile1}>
        <div className={classes.outerBorderSmallThird}>
          <div className={classes.hexaInnerSmall}>
            <span className={classes.numberInside}>01</span>
          </div>
        </div>
        <div className={classes.outerBorderLargeSecond}>
          <div className={classes.hexaInnerLarge}>
            <div className={classes.hexagoninfo}>
            <img
                src={Lotteryicon}
                alt="Lottery icon"
                className={classes.iconStyle}
              />
              <div className={classes.titleParagraphDesktop}>
                <span className={classes.hexaTitle}>
                  Lottery <span className={classes.hexaSecondTitle}>Studio</span>
                </span>
                <p className={classes.hexaParagraph}>
                  Elevate your gaming experience with our cutting-edge Lottery
                  iStudio. Choose from a diverse selection of lottery games, all
                  designed with fairness, transparency, and top-tier security in
                  mind.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.hexaAllContMobile2}>
        <div className={classes.outerBorderLargeSecond}>
          <div className={classes.hexaInnerLarge}>
            <div className={classes.hexagoninfo}>
              <img
                src={Sportsbeticon}
                alt="Sportsbet icon"
                className={classes.iconStyle}
              />
              <div className={classes.titleParagraphDesktop}>
                <span className={classes.hexaTitle}>
                  Sports <span className={classes.hexaSecondTitle}>Betting</span>
                </span>
                <p className={classes.hexaParagraph}>
                  Dive into the thrill of sports with our advanced Sports Betting
                  platform. Place bets on your favorite teams and events with
                  competitive odds, live betting, and instant updates. Enjoy a
                  secure, seamless, and exciting betting experience.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.outerBorderSmallThird}>
          <div className={classes.hexaInnerSmall}>
            <span className={classes.numberInside}>02</span>
          </div>
        </div>
      </div>

      <div className={classes.hexaAllContMobile1}>
        <div className={classes.outerBorderSmallThird}>
          <div className={classes.hexaInnerSmall}>
            <span className={classes.numberInside}>03</span>
          </div>
        </div>
        <div className={classes.outerBorderLargeSecond}>
          <div className={classes.hexaInnerLarge}>
            <div className={classes.hexagoninfo}>
            <img
                src={OnlineCasinoicon}
                alt="OnlineCasino icon"
                className={classes.iconStyle}
              />
              <div className={classes.titleParagraphDesktop}>
                <span className={classes.hexaTitle}>
                  Online <span className={classes.hexaSecondTitle}>Casino</span>
                </span>
                <p className={classes.hexaParagraph}>
                  Discover the ultimate gaming adventure in our Online Casino.
                  Enjoy a premium casino experience from anywhere. Benefit from
                  stunning graphics, fair play, and the chance for big wins, all
                  in one secure platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyGames;
